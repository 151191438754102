import React from "react" 
import ImageMeta from "../components/ImageMeta"
import ResponsiveImage from "../components/ResponsiveImage";
import Layout from "../components/layout"
import Seamless from "../components/Seamless"
import { SharedStateProvider } from "../components/SharedState/PageSharedState"
import SEO from "../components/seo"

import TheaterVideo from "@components/Theater/TheaterVideo";
import Button from '@bit/azheng.joshua-tree.button';
import ReviewRow from "../components/ReviewRow"
import CTA from "../components/CTA/CTA"
import GetStarted from "../components/CTA/GetStarted"
import HeadingParagraphButton from "../components/CTA/HeadingParagraphButton"

import { Dialog } from "@blueprintjs/core";

import {
  Image,
  Video,
  Transformation,
  CloudinaryContext,
} from "cloudinary-react"
import { Link, useStaticQuery, graphql } from "gatsby"
import { instructions } from "../cms/collections/instructions"
import stars from "../images/stars.svg"

var classNames = require("classnames")

function createHtml(html) {
  return { __html: html }
}
var showdown = require("showdown")
const converter = new showdown.Converter()

class iCatPage extends React.Component  {

    constructor(props) {
        super(props);
      }


render() {  
  const language = this.props.pageContext.language;

  const post = this.props.data.allUniquePagesJson.nodes[0];
    return (
    <SharedStateProvider>

      <Layout pageTitle="i-cat-page">
      <SEO title={post.metaTitle} description={post.metaDescription} pathname={this.props.location.pathname} schemaPage={post.schemaPage ? post.schemaPage : null} />
        <div>
          <Button buttonText="BACK" goBack />
          <div className="procedure-video-top-wrapper">
          <ResponsiveImage
            desktopImageId={post.imageId}
            mobileImageId={post.imageIdMobile}
            />
          <div className="columns procedure-heading-container is-vcentered">
            <div className="column is-2"></div>

            <div className="column">
            {
              (post.youtube) &&
              <div className="column is-4 procedures-theater-button is-hidden-tablet">
                {post.youtube && (
                  <TheaterVideo
                    language={language}
                    videoUrl={`https://www.youtube.com/watch?v=${post.youtube}`}
                    controls={true}
                    playing={true}
                    overVideo={true}
                    hasWatchVideo={true}
                    buttonClass="contained"
                  />
                )}
            </div>
            }
              <div
                className={`procedure-video-top-headings ${
                  post.whiteTopHeadings ? "white-top-headings" : ""
                }`}
              >
                <h1
                  dangerouslySetInnerHTML={createHtml(post.heading)}
                  style={{
                    fontSize:
                      post.name === "Orthognathic Surgery" ? "2.2rem" : "",
                  }}
                ></h1>
                <h2 style={{ margin: 0 }}>{post.subheading}</h2>
              </div>
            </div>

            {
              (post.youtube) &&
              <div className="column is-4 procedures-theater-button is-hidden-mobile">
                {post.youtube && (
                  <TheaterVideo
                    language={language}
                    videoUrl={`https://www.youtube.com/watch?v=${post.youtube}`}
                    controls={true}
                    playing={true}
                    overVideo={true}
                    hasWatchVideo={true}
                    buttonClass="contained"
                  />
                )}
            </div>
            }
            <div className="column is-2"></div>
          </div>

          {/* <div className="columns">
            <div className="column is-2"></div>
            <div className="column">
              <h1 className="single-procedure-heading-mobile" dangerouslySetInnerHTML={createHtml(post.heading)}></h1>
            </div>
            <div className="column is-6">
            </div>
          </div> */}
          
        </div>
        </div>


<div className="columns">
    <div className="column is-full-width has-text-centered">
    {/* <TheaterVideo
        videoUrl={`https://www.youtube.com/watch?v=${post.youtube}`}
                  controls={true}
                  playing={true}
                  overVideo={post.imageId ? true : false}
                  onlyButton={post.imageId ? false : true}

                >
{post.imageId &&                  <ImageMeta
                    cloudName="nuvolum"
                    publicId={post.imageId}
                    
                    width="auto"
                  >
                    
                  </ImageMeta>}
                </TheaterVideo> */}

    </div>
</div>

<div className="procedure-body three-d-body">
  <section className="body-sections section">
    <div className="columns">
      <div className="column is-5"></div>
      <div className="column" dangerouslySetInnerHTML={createHtml(
              converter.makeHtml(post.blurb + post.body)
            )}></div>
      <div className="column is-5"></div>
    </div>
  </section>
</div>



<GetStarted
            className="get-started-cta three-d-cta color-back"
            centerHeading
            centerText
            headingSideColumnIs={5}
            sideColumnIs={4}
            subheading={post.getStarted.subheading}
            heading={post.getStarted.heading}
            paragraph={<div dangerouslySetInnerHTML={createHtml(
              converter.makeHtml(post.getStarted.blurb)
            )}></div>}
            buttonText={post.getStarted.buttonText}
            buttonUrl={post.getStarted.href}
          />

      </Layout>
    </SharedStateProvider>
  )}
}

export const pageQuery = graphql`
query threeDImagingPage($title: String!)  {
  allUniquePagesJson(filter: {title: {eq: $title}}) {
    nodes {
      title
      heading
      blurb
      metaTitle
      metaDescription
      schemaPage
      youtube
      imageId
      imageIdMobile
      body
      getStarted {
        blurb
        buttonText
        heading
        href
        subheading
      }
    }
  }
}

`

export default iCatPage
